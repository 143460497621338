<template>
	<div class="login-container">
		<div class="app-container">
			<div class="logo-container">
				<!--<div :style="`background-image:url(${logSrc})`"></div>!-->
				<img :src="logSrc" />
				<!--<h1>百森咨询</h1>!-->
			</div>
			<el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on" label-position="left">
				<div class="title-container">
					<!-- <span @click="activeTab('first')" :class="activeName == 'first' ? 'active' : ''">管理员登录</span> -->
					<!-- <el-divider direction="vertical"></el-divider> -->
					<!-- <span @click="activeTab('second')" :class="activeName == 'second' ? 'active' : ''">员工登录</span> -->
				</div>
				<!-- <div @click="changeLoginWay" :class="!userqrCode ? 'iconImg' : 'iconImg1'"></div> -->
				<section v-show="!userqrCode">
					<el-form-item prop="username">
						<span class="svg-container">
							<img style="vertical-align: middle" src="/user.png" />
						</span>
						<el-select
							style="width: 85px; border-right: 1px solid #dcdfe6"
							v-model="loginForm.areaCode"
							tabindex="-1"
							placeholder="请选择"
						>
							<el-option label="+86" value="86"></el-option>
							<el-option label="+852" value="852"></el-option>
							<el-option label="+853" value="853"></el-option>
							<el-option label="+886" value="886"></el-option>
						</el-select>
						<el-input
							ref="username"
							v-model="loginForm.username"
							placeholder="请输入手机号码"
							name="username"
							type="text"
							maxlength="11"
							tabindex="2"
							style="width: 63%"
							auto-complete="off"
						>
						</el-input>
					</el-form-item>
					<el-form-item prop="password" v-if="type == 'password'">
						<span class="svg-container">
							<img style="vertical-align: middle" src="/pwd.png" />
						</span>
						<el-input
							ref="password"
							v-model="loginForm.password"
							@keyup.native="loginKeyDown"
							type="password"
							placeholder="请输入密码"
							name="password"
							tabindex="3"
							auto-complete="on"
							show-password
							style="width: 90%"
						/>
						<!-- <div style="height:15px;position:absolute;top:35px;right:10px;">&nbsp;
            <el-tag v-show="bigChar"
                    style="margin-left:20px">大写锁定已打开</el-tag>
          </div> -->
					</el-form-item>
					<el-form-item prop="checkCode" class="phoneCode" v-if="type != 'password'">
						<el-input
							ref="password"
							v-model="loginForm.checkCode"
							placeholder="请输入手机验证码"
							name="password"
							maxlength="6"
							tabindex="4"
							auto-complete="on"
						/>
						<el-button class="PhonecodeBtn" type="primary" @click="getCode" :disabled="time != 0">{{
							time ? time + 's后重发' : '获取验证码'
						}}</el-button>
					</el-form-item>
					<el-form-item prop="code" style="width: 58%">
						<div class="item_code">
							<div>
								<span class="svg-container">
									<img style="vertical-align: middle" src="/code.png" />
								</span>
								<el-input
									v-model="loginForm.code"
									placeholder="请输入图形验证码"
									name="VerificationCode"
									tabindex="5"
									@keyup.enter.native="handleLogin"
									style="width: 75%"
								/>
							</div>
						</div>
					</el-form-item>
					<img
						:src="imgurl"
						:style="activeName == 'second' ? 'bottom:104px' : ''"
						alt="Picture loading failed"
						class="codeimg"
						@click="getcaptchaimg"
					/>
					<el-button :loading="loading" type="primary" style="width: 100%" @click.native.prevent="handleLogin">登录</el-button>
				</section>
				<section v-show="userqrCode" style="text-align: center">
					<div
						v-show="end"
						class="errordis"
						@click="
							activeTab();
							end = false;
						"
					>
						<p>二维码已失效</p>
						<p>点击刷新二维码<i class="el-icon-refresh"></i></p>
					</div>
					<qrcode :url="qrCodeurl" iconurl="/img/icons/favicon-32x32.png" :wid="200" :hei="200" :imgwid="53" :imghei="53"></qrcode>
				</section>
				<div class="footerbox" v-show="activeName == 'first' && !userqrCode">
					<span @click="dialogFormVisible = true" class="link">忘记密码</span>
					<el-divider direction="vertical"></el-divider>
					<span @click="goregister" class="link">注册</span>
					<el-link :underline="false" type="primary" class="link" style="float: right" @click="changeType">{{
						type == 'password' ? '验证码登录' : '密码登录'
					}}</el-link>
					<!-- <span @click="changeLoginWay" style="float:right">{{text}}</span> -->
				</div>
				<div class="footerbox" v-show="activeName == 'second' && !userqrCode">
					<span @click="dialogFormVisible = true" style="transform: translateY(-1px)" class="link">忘记密码</span>
					<el-link :underline="false" type="primary" style="float: right" class="link" @click="changeType">{{
						type == 'password' ? '验证码登录' : '密码登录'
					}}</el-link>
					<!-- <span @click="changeLoginWay" style="float:right">{{text}}</span> -->
				</div>
			</el-form>
		</div>
		<el-dialog title="找回密码" center :visible.sync="dialogFormVisible" @close="handleFindClose" width="30%">
			<el-form
				ref="findForm"
				:rules="findRules"
				:model="findForm"
				class="login-form find-form"
				style="width: 100%; float: none; padding: 0; margin: 0"
			>
				<span></span>
				<el-form-item prop="phoneNumber">
					<el-select style="width: 95px; border-right: 1px solid #dcdfe6" v-model="findForm.areaCode" tabindex="-1" placeholder="请选择">
						<el-option label="+86" value="86"></el-option>
						<el-option label="+852" value="852"></el-option>
						<el-option label="+853" value="853"></el-option>
						<el-option label="+886" value="886"></el-option>
					</el-select>
					<el-input
						ref="username"
						maxlength="11"
						v-model="findForm.phoneNumber"
						placeholder="请输入手机号码"
						name="username"
						type="text"
						tabindex="2"
						style="width: 65%"
						auto-complete="off"
					>
					</el-input>
				</el-form-item>
				<el-form-item prop="phoneCode" class="phoneCode">
					<el-input
						ref="password"
						v-model="findForm.phoneCode"
						placeholder="请输入手机验证码"
						name="password"
						maxlength="6"
						tabindex="3"
						auto-complete="on"
					/>
					<el-button
						style="height: 47px; width: 120px"
						class="PhonecodeBtn"
						type="primary"
						@click="getFindCode"
						:disabled="findTime != 0"
						>{{ findTime ? findTime + 's后重发' : '获取验证码' }}</el-button
					>
				</el-form-item>
				<el-form-item prop="newPassword">
					<el-input
						placeholder="请输入密码(8-20位，同时包含数字和字母)"
						v-model="findForm.newPassword"
						type="password"
						tabindex="4"
						autocomplete="off"
					></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="handleFindClose">取消</el-button>
				<el-button type="primary" @click="submitFind">确定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import md5 from 'js-md5';
import qrcode from 'vue_qrcodes';
import { Base64 } from 'js-base64';
import { getcaptchaimg } from '@/api/common';
import * as api from '@/api/loginInfo';
import { getCookie, delCookie } from '../../utils/util';
import * as sysApi from '@/api/workbench';
export default {
	name: 'Login',
	components: {
		qrcode
	},
	data() {
		const validateUsername = (rule, value, callback) => {
			// var re = /^[1][3-8]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^[6]([8|6])\d{5}$/;
			if (value === '') {
				callback(new Error('请输入手机号码'));
			} else if (value.length > 11) {
				callback(new Error('请输入正确手机号码'));
			}
			callback();
		};
		const validatePassword = (rule, value, callback) => {
			if (value == '') {
				callback(new Error('请输入密码'));
			}
			if (value.length < 8) {
				callback(new Error('请输入8-20位字符，必须包含字母和数字'));
			} else {
				callback();
			}
		};

		return {
			bigChar: false,
			logSrc: '',
			activeName: 'first',
			type: 'password',
			userqrCode: false,
			end: false,
			qrCodeurl: '',
			text: '授权登录',
			loginForm: {
				username: '',
				password: '',
				// phonecode: "",
				key: '',
				areaCode: '86',
				checkCode: ''
			},
			findForm: {
				phoneNumber: '',
				newPassword: '',
				phoneCode: '',
				qrurl: '',
				areaCode: '86'
			},
			formLabelWidth: '80px',
			dialogFormVisible: false,
			imgurl: '',
			form: {
				oldPassword: '',
				newPassword: '',
				confirmPassword: ''
			},
			time: 0,
			findTime: 0,
			loginRules: {
				username: [{ required: true, trigger: 'blur', validator: validateUsername }],
				password: [{ required: true, trigger: 'blur', validator: validatePassword }],
				code: [{ required: true, trigger: 'blur', message: '请输入验证码' }],
				checkCode: [{ required: true, trigger: 'blur', message: '请输入验证码' }]
			},
			findRules: {
				phoneNumber: [{ required: true, trigger: 'blur', validator: validateUsername }],
				newPassword: [{ required: true, trigger: 'blur', validator: validatePassword }],
				phoneCode: [{ required: true, trigger: 'blur', message: '请输入验证码' }]
			},
			loading: false,
			rememberPwd: false,
			redirect: undefined
		};
	},
	watch: {
		$route: {
			handler(route) {
				this.redirect = route.query && route.query.redirect;
			},
			immediate: true
		}
	},
	methods: {
		init() {
			this.getcaptchaimg();
		},
		getqrCode() {
			// if(!this.userqrCode){
			api.qrCodeCreate({ type: this.activeName == 'first' ? '1' : '2' }).then((res) => {
				this.qrCodeurl = res.qrCode;
				let time = 30;

				const loginInter = setInterval(() => {
					if (this.userqrCode) {
						time--;
						if (this.userqrCode && time == 0) {
							this.end = true;
							clearInterval(loginInter);
							return false;
						}
						api.getQrCodeResult({ qrCode: this.qrCodeurl }).then(async (res) => {
							if (res.code === 0) {
								if (getCookie('token')) {
									if (this.activeName == 'first') {
										await this.getUserInfo();
										this.getrange();
										clearInterval(loginInter);
										this.$router.push({ path: '/' });
									} else if (this.activeName == 'second') {
										clearInterval(loginInter);
										this.$router.push({ path: '/staff' });
										this.getrange();
									}
								}
							}
						});
					} else {
						clearInterval(loginInter);
					}
				}, 2000);
			});
			// }
			// this.userqrCode=!this.userqrCode;
		},
		changeType() {
			if (this.type == 'password') {
				this.$refs.loginForm.clearValidate('password');
				this.type = 'codelogin';
			} else {
				this.$refs.loginForm.clearValidate('checkCode');
				this.type = 'password';
			}
		},
		activeTab(name) {
			if (name) {
				this.activeName = name;
			}
			this.$refs.loginForm.clearValidate();
			if (this.userqrCode) {
				// this.userqrCode=false;
				this.getqrCode();
			}
		},
		changeLoginWay() {
			if (this.userqrCode) {
				this.text = '授权登录';
			} else {
				this.text = '手机号登录';
				this.end = false;
				this.getqrCode();
			}
			this.userqrCode = !this.userqrCode;
		},
		loginKeyDown(event) {
			const _that = this;
			// 是否输入过字母键，且判断是否按键为caps lock

			if (_that.firstTochar) {
				if (event.keyCode === 20) {
					_that.bigChar = !_that.bigChar;
					return;
				}
			}
			// 未输入过字母键，或按键不是caps lock，判断每次最后输入的字符的大小写
			const e = event || window.event;

			const keyvalue = e.keyCode ? e.keyCode : e.which;

			const shifKey = e.shiftKey ? e.shiftKey : keyvalue == 16;

			if (typeof _that.loginForm.password === 'undefined') {
				return;
			}
			const strlen = _that.loginForm.password.length;

			const { password } = _that.loginForm;

			if (strlen) {
				const uniCode = password.charCodeAt(strlen - 1);

				if (keyvalue >= 65 && keyvalue <= 90) {
					// 如果是字母键
					_that.firstTochar = true;
					if ((uniCode >= 65 && uniCode <= 90 && !shifKey) || (uniCode >= 97 && uniCode <= 122 && shifKey)) {
						_that.bigChar = true;
					} else {
						_that.bigChar = false;
					}
				}
			}
		},
		async getcaptchaimg() {
			const res = await getcaptchaimg({ request: {} });

			if (res) {
				this.imgurl = `data:image/png;base64,${res.base64}`;
				this.loginForm.key = res.key;
			}
		},
		goregister() {
			this.$router.push({ path: '/register' });
		},
		getCode() {
			if (this.loginForm.username != '') {
				this.time = 60;
				var timeout = setInterval(() => {
					this.time--;
					if (!this.time) {
						window.clearInterval(timeout);
					}
				}, 1000);

				api.getSms({
					request: {
						type: '2',
						phoneNum: this.loginForm.username,
						areaCode: this.loginForm.areaCode
					}
				});
			}
		},
		getFindCode() {
			if (this.findForm.phoneNumber != '') {
				this.findTime = 60;
				var timeout = setInterval(() => {
					this.findTime--;
					if (!this.findTime) {
						window.clearInterval(timeout);
					}
				}, 1000);

				api.getSms({
					request: {
						type: '6',
						phoneNum: this.findForm.phoneNumber,
						areaCode: this.findForm.areaCode
					}
				});
			}
		},
		changepwd() {},
		getUserPwd() {
			const user = Base64.decode(localStorage.getItem('user') || '');

			const pwd = Base64.decode(localStorage.getItem('pwd') || '');

			if (user !== '' && pwd !== '') {
				this.loginForm.username = user;
				this.loginForm.password = pwd;
				this.rememberPwd = true;
			}
		},
		// 提交找回密码表单
		submitFind() {
			this.$refs.findForm.validate(async (valid) => {
				if (valid) {
					this.findForm.newPassword = md5(this.findForm.newPassword);
					api.findPwd({
						request: this.findForm
					}).then((res) => {
						if (res.code == 0) {
							this.$message.success('找回密码成功，请重新登录');
							this.handleFindClose();
						}
					});
				}
			});
		},
		handleFindClose() {
			this.$refs.findForm.resetFields();
			this.$refs.findForm.clearValidate();

			this.dialogFormVisible = false;
		},
		handleLogin() {
			this.$refs.loginForm.validate(async (valid) => {
				if (valid) {
					if (this.rememberPwd === true) {
						localStorage.setItem('user', Base64.encode(this.loginForm.username || ''));
						localStorage.setItem('pwd', Base64.encode(this.loginForm.password || ''));
					} else {
						localStorage.removeItem('user');
						localStorage.removeItem('pwd');
					}
					this.loading = true;
					const request = {
						phoneNumber: this.loginForm.username,
						password: this.type == 'password' ? md5(this.loginForm.password) : '',
						checkCode: this.type != 'password' ? this.loginForm.checkCode : '',
						key: this.loginForm.key,
						type: this.type == 'password' ? '1' : '2',
						imageCode: this.loginForm.code,
						areaCode: this.loginForm.areaCode
					};

					if (this.type == 'password') {
						if (this.activeName == 'first') {
							const res = await api.login({ request });

							if (res.code == '0') {
								// this.$store.dispatch("app/setPermission");
								await this.getUserInfo();
								this.getrange();
								this.$router.push({ path: '/' });
							} else {
								this.getcaptchaimg();
								this.loading = false;
								return;
							}
						} else if (this.activeName == 'second') {
							const res = await api.staffLogin({ request });

							if (res.code == '0') {
								// this.$store.dispatch("app/setPermission");
								// this.getUserInfo();
								this.$router.push({ path: '/staff/talent_planning' });
								this.getrange();
							} else {
								this.getcaptchaimg();
								this.loading = false;
								return;
							}
						}
					} else if (this.activeName != 'password') {
						if (this.activeName == 'first') {
							const res = await api.login({ request });

							if (res.code == '0') {
								// this.$store.dispatch("app/setPermission");
								await this.getUserInfo();
								this.getrange();
								this.$router.push({ path: '/' });
							} else {
								this.getcaptchaimg();
								this.loading = false;
								return;
							}
						} else if (this.activeName == 'second') {
							const res = await api.staffLogin({ request });

							if (res.code == '0') {
								// this.$store.dispatch("app/setPermission");
								// this.getUserInfo();
								this.$router.push({ path: '/staff' });
								this.getrange();
							} else {
								this.getcaptchaimg();
								this.loading = false;
								return;
							}
						}
					}
					// 如果是 wap 页面,不请求下列接口,以避开鉴权
					// eslint-disable-next-line wrap-regex
					if (/^http(s)?:\/\/.+\/wap(\/)?.*$/.test(location.href)) {
						return;
					}
					// 如果是 员工系统 页面,只请求下列接口,以避开鉴权
					// if (/^http(s)?:\/\/.+\/staff(\/)?.*$/.test(location.href)) {
					//   this.getPermission();
					//   this.getDict();
					//   this.queryDepartment();
					//   this.selectPrincipal();
					//   return
					// }
					// this.getPermission();
					this.getPermissionData();
					this.getDict();
					this.queryDepartment();
					if (this.activeName == 'first') {
						this.queryPosition();
						// this.costCenter();
					}
					this.selectPrincipal();
					this.loading = false;
					// })
				} else {
					return false;
				}
			});
		},

		async getUserInfo() {
			return api
				.getUserInfo({
					request: {}
				})
				.then((res) => {
					this.userInfo = res;
					sessionStorage.setItem('users', JSON.stringify(res));
					this.$store.commit('app/SET_STAFFID', res.staffId);
					this.$store.commit('app/SET_COMPANYPRIN', res.companyPrin);
					this.$store.commit('app/SET_USERTYPE', res.userType);
					localStorage.setItem('comId', res.comId);
					this.$store.commit('app/SET_ORGID', res.orgId);
					sessionStorage.setItem('comName', res.comName);
				});
		},
		getLoginImg() {
			let localArr = localStorage.getItem('loginImg');

			// eslint-disable-next-line no-eq-null
			if (localArr != null) {
				localArr = JSON.parse(localArr);
				if (localArr.backgroundImage) {
					const bgImg = JSON.parse(localArr.backgroundImage);

					document.getElementsByClassName('login-container')[0].style.backgroundImage = `url('${bgImg[0].url}')`;
				}
				if (localArr.bigLogoImage) {
					const logImg = JSON.parse(localArr.bigLogoImage);

					this.logSrc = logImg[0].url;
				} else {
					this.logSrc = '/loginLogo.png';
				}
			} else {
				this.logSrc = '/loginLogo.png';
			}
		},
		getPermission() {
			this.$store.dispatch('app/setPermission');
		},
		getPermissionData() {
			this.$store.dispatch('app/setMenusPermission');
		},
		getrange() {
			this.$store.dispatch('app/setrange');
		},
		getDict() {
			this.$store.dispatch('app/getDict');
		},
		// 获取职务
		queryPosition() {
			sysApi
				.queryPosition({
					request: {}
				})
				.then((res) => {
					this.$store.commit('SET_POSITIONLIST', res);
				})
				.catch(() => {});
		},
		// 获取成本中心
		costCenter() {
			sysApi
				.costCenter({
					request: {}
				})
				.then((res) => {
					this.$store.commit('SET_CENTERLIST', res);
				})
				.catch(() => {});
		},

		// 部门
		queryDepartment() {
			sysApi
				.queryDepartment({
					request: {}
				})
				.then((res) => {
					this.$store.commit('SET_DEPT', res);
				})
				.catch(() => {});
		},
		// 汇报对象
		selectPrincipal() {
			sysApi
				.selectPrincipal({
					request: {}
				})
				.then((res) => {
					this.$store.commit('SET_PRINCIPALLIST', res);
				})
				.catch(() => {});
		}
	},
	mounted() {
		delCookie('token');
		this.getLoginImg();
		this.init();
		this.getUserPwd();
	}
};
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

// $bg: #283443;
$light_gray: #fff;
$cursor: #fff;

// @supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
//   .login-container .el-input input {
//     color: $cursor;
//   }
// }

/* reset element-ui css */
.area .el-input {
	width: 100%;
}
.login-container {
	width: 100%;
	height: 100%;
	background: url('/loginBg2.png') no-repeat center center fixed;
	background-size: cover;
	.app-container {
		padding: 0;
		.backgroundBox {
			width: 60%;
			margin: 40px 0 40px 40px;
			display: inline-block;
			img {
				width: 100%;
			}
		}
		.title-container .el-divider {
			margin-top: 3px;
			background: #7e7e7e;
		}
		.titlebox {
			text-align: left;
			line-height: 17px;
			margin: 20px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			> .el-button {
				color: #484848;
				transform: translateY(-8px);
				font-size: 16px;
			}
			.register {
				color: #fff;
				font-size: 14px;
				float: right;
				margin-top: 13px;
			}
			img {
				vertical-align: bottom;
				margin-right: 10px;
				object-fit: cover;
			}
		}
	}
	.login-form {
		.el-form-item__content {
			border: 1px solid #dbdbdb;
			border-radius: 4px;
		}
	}
	.find-form .el-form-item {
		// border: 1px solid #c5cdda;
		border-radius: 4px;
	}
	.login-form .phoneCode {
		.el-form-item__content {
			border: none;
			.el-input {
				width: 58%;
				.el-input__inner {
					border: 1px solid #dcdfe6;
					border-radius: 4px;
				}
			}
		}
	}
	.find-form .phoneCode {
		.el-form-item__content {
			border: none;
			.el-input {
				width: 67%;
				.el-input__inner {
					border: 1px solid #dcdfe6;
					border-radius: 4px;
				}
			}
		}
	}
	.ChangepwdBox {
		.el-input {
			width: 90%;
		}
	}
	.footerbox {
		transform: translateY(10px);
		overflow: hidden;
		// >span {
		//   color: #ffffff;
		// }
		.link {
			color: #414141;
		}
		.link:hover {
			color: #498df0;
			text-decoration: underline;
		}
		span {
			cursor: pointer;
			float: left;
			font-size: 12px;
			font-family: 'PingFangSC-Regular';
		}
		.el-divider {
			float: left;
		}
	}
	.login-form .el-form-item {
		// border: 1px solid #dcdfe6;
		// border-radius: 5px;
		color: #454545;
		position: relative;
		.el-input {
			display: inline-block;
			height: 47px;
			width: 100%;
			background: #fff;
			border-radius: 4px;
			input {
				background: transparent;
				border: 0px;
				-webkit-appearance: none;
				border-radius: 0px;
				padding: 12px 5px 12px 15px;
				// color: $light_gray;
				height: 47px;
				// caret-color: $cursor;

				&:-webkit-autofill {
					box-shadow: 0 0 0px 1000px white inset;
					// border: 1px solid #CCC!important;
					// box-shadow: 0 0 0px 1000px $bg inset !important;
					// -webkit-text-fill-color: $cursor !important;
				}
			}
		}
		.PhonecodeBtn {
			position: absolute;
			right: 0;
			min-width: 120px;
			height: 47px;
			border: none;
			border-radius: 4px;
			border-left: 1px solid #ccc;
		}
	}
}
</style>

<style lang="scss" scoped>
// $bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;
.errordis {
	width: 201px;
	position: absolute;
	height: 201px;
	color: white;
	line-height: 30px;
	padding-top: 60px;
	left: 50%;
	background: rgba(0, 0, 0, 0.6);
	z-index: 10;
	transform: translateX(-50%);
}
.login-container {
	min-height: 100%;
	width: 100%;
	overflow: hidden;

	.app-container {
		width: 400px;
		height: 410px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background: none;
		.logo-container {
			text-align: center;
			margin-bottom: 40px;
			img {
				width: 250px;
				height: 74px;
			}
		}
	}
	.login-form {
		background: rgb(255, 255, 255);
		border: 1px solid rgb(255, 255, 255);
		border-radius: 8px;
		padding: 30px 40px;
		position: relative;
		// background-color: rgba(0,0,0,0);
		overflow: hidden;
		.iconImg {
			position: absolute;
			width: 42px;
			height: 42px;
			background-image: url('/erwei.png');
			background-size: 100% 100%;
			top: 6px;
			right: 5px;
		}
		.iconImg:hover {
			background-image: url('/erweihover.png');
		}
		.iconImg1 {
			position: absolute;
			width: 42px;
			height: 42px;
			background-image: url('/password.png');
			background-size: 100% 100%;
			top: 6px;
			right: 5px;
		}
		.iconImg1:hover {
			background-image: url('/passwordhover.png');
		}
	}

	.tips {
		font-size: 14px;
		color: #fff;
		margin-bottom: 10px;

		span {
			&:first-of-type {
				margin-right: 16px;
			}
		}
	}

	.svg-container {
		padding: 0px 5px 0px 4px;
		// color: $dark_gray;
		// vertical-align: middle;
		width: 30px;
		display: inline-block;
	}
	.item_code {
		display: flex;
		justify-content: center;
		align-items: center;
		justify-content: space-between;
	}
	.codeimg {
		position: absolute;
		right: 42px;
		bottom: 108px;
		width: 120px;
		height: 48px;
		border: 1px solid #dbdbdb;
		border-radius: 5px;
	}

	.title-container {
		display: flex;
		justify-content: center;
		span {
			margin: 0px 0px 20px;
			cursor: pointer;
			position: relative;
			font: 600 16px 'PingFangSC-Semibold';
			color: #414141;
		}
		span.active {
			color: #498df0;
		}
	}

	.show-pwd {
		position: absolute;
		right: 10px;
		top: 7px;
		font-size: 16px;
		color: $dark_gray;
		cursor: pointer;
		user-select: none;
	}
}
</style>
